import React, { useState } from 'react';
import Modal from 'react-modal';
import './Developer.css';
import { Link as Anchor } from 'react-router-dom';

export default function Developer() {
    const [modalIsOpen, setModalIsOpen] = useState(false);

    const handleCloseModal = () => {
        setModalIsOpen(false);
    };

    const handleOpenModal = () => {
        setModalIsOpen(true);
    };

    const handleWhatsappMessage = () => {
        const phoneNumber = 5493855865353;
        const whatsappUrl = `https://api.whatsapp.com/send?phone=${phoneNumber}`;
        window.open(whatsappUrl, '_blank');
    };

    return (
        <div className='Developer'>
            <p onClick={handleOpenModal}>
                Crea tu E-commerce con nosotros - Creat
            </p>

            <Modal
                isOpen={modalIsOpen}
                onRequestClose={handleCloseModal}
                className="modal-dev"
                overlayClassName="overlay-dev"
                ariaHideApp={false}
            >
                <div className='modal-dev-contain'>
                    <img src="https://scontent.feze17-1.fna.fbcdn.net/v/t39.30808-6/412300154_122098238108161064_1201834714275471055_n.png?_nc_cat=106&ccb=1-7&_nc_sid=cc71e4&_nc_ohc=P0gw5bKLt2IQ7kNvgFHPu5W&_nc_ht=scontent.feze17-1.fna&gid=Ajp8S42UCrH3xTKuY2xUozY&oh=00_AYCaspelCam3oyTSiPAP7oupRMnc8G5EHT44sveF73XM3g&oe=66B1D0D4" alt="Juan Rivera" className='banner-dev-modal' />
                    <div className='text-dev-modal'>
                        <img src="https://scontent.feze17-1.fna.fbcdn.net/v/t39.30808-1/410755025_122098212140161064_5340500138628433685_n.jpg?stp=dst-jpg_p200x200&_nc_cat=102&ccb=1-7&_nc_sid=f4b9fd&_nc_ohc=gpWq2JcXA4IQ7kNvgFGFUdh&_nc_ht=scontent.feze17-1.fna&gid=A95lOduNz2UWWW8VRgNc4LO&oh=00_AYClL-z_I_zmz9dI9sp-cRvr8DuqDz6eKqCBkySzvjN_Ow&oe=66B1E856" alt="Creat" />
                        <h2>Contactanos <br />  <Anchor to='https://Creat.com.ar/' target="_blank">Creat - Marketing Digital</Anchor></h2>
                        <div className='socialsDev'>
                            <Anchor to='https://www.instagram.com/creat.ok' target="_blank"><i className='fa fa-instagram' id="instagram"></i></Anchor>
                            <Anchor to='https://Creat.com.ar/' target="_blank"><i className='fa-regular fa-globe' id="globe"></i></Anchor>
                            <Anchor onClick={handleWhatsappMessage}><i className='fa fa-whatsapp' id="whatsapp"></i></Anchor>
                        </div>
                        <button onClick={handleCloseModal}>OK</button>
                    </div>
                </div>
            </Modal>
        </div>
    );
}
